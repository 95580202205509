import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Service from "../Services/Service";

export default function CareersExperienced() {
  const [loading, setLoading] = useState(false);
  //showing alert-
  const [isShow, setIsShow] = useState(false);
  const [alertClass, setAlertClass] = useState();
  const [msg, setMsg] = useState();
  const [resumeValidation, setResumeValidation] = useState(false);

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  //savedata
  const loadExperianceData = (data) => {
    //validation for resume
    if (data.Resume[0].type === "application/pdf") {
      let fd = new FormData();
      fd.append("Post", data.Post);
      fd.append("Experiance", data.Experiance);
      fd.append("PrefferedMode", data.PrefferedMode);
      fd.append("CurrentCTC", data.CurrentCTC);
      fd.append("Name", data.Name);
      fd.append("Address", data.Address);
      fd.append("Contact", data.Contact);
      fd.append("Email", data.Email);
      fd.append("Resume", data.Resume[0]);
      setLoading(true);
      Service.careerExperiance(fd).then((res) => {
        if (res.data.Success === "Data Saved sucessfully.") {
          //alert
          // setIsShow(true);
          // setAlertClass("alert alert-success");
          // setMsg(res.data.Success);
          // setMsg('Success. Please wait for our email or call back. Thank you.');
          setLoading(false);
          alert('Success. Thank you for showing interest in Arnav Technosys \n\nPlease wait for our email or call back. ');
          reset();

        } else if (res.data.warning === "Contact already exists...!") {
          setLoading(false);
          setIsShow(true);
          setAlertClass("alert alert-danger");
          setMsg(res.data.warning);
        }
        setTimeout(() => { setIsShow(false); }, 5000);
      });
    } else {
      setResumeValidation(true);
      setAlertClass("alert alert-danger");
      setMsg(`Invalid file format. Please upload a valid file format. e.g. pdf, doc, docx`);
      setLoading(false);
      setTimeout(() => { setIsShow(false); setResumeValidation(false); }, 5000);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(loadExperianceData)}>
        <ul>
          <li>
            <div className="content">
              <div className="career-number"><strong>1</strong></div>
              <h3 className="pt-2 text-dark">Job Details</h3>
            </div>

            <div className="contact-form mt-3">
              <div className="row">
                <div className="col-sm-6">
                  <span>
                    <select className="nice-select" type="text" id=""
                      {...register("Post", { required: "Please select post." })}>
                      <option value="">Apply For</option>
                      <option value=".NET Full-Stack Developer(2-7 Years)">.NET Full-Stack Developer(2-7 Years)</option>
                      <option value="Java Fullstack Microservices Developer (2-6 Years)">Java Fullstack Microservices Developer (2-6 Years)</option>
                      <option value="NodeJS Developer (2-5 Years)">NodeJS Developer (2-5 Years)</option>
                      <option value="Frontend Developer - ReactJS/Redux (2-4 Years)">Frontend Developer - ReactJS/Redux (2-4 Years)</option>
                      <option value="Frontend Developer - Angular (2-4 Years)">Frontend Developer - Angular (2-4 Years)</option>
                      <option value="PHP Developer/WordPress (2-5 Years)">PHP Developer/WordPress (2-5 Years)</option>
                      <option value="PHP Developer - Laravel/CodeIgniter (2-5 Years)">PHP Developer - Laravel/CodeIgniter (2-5 Years)</option>
                    </select>
                    <label className="input_label"><span className="input__label-content"></span></label>
                    {errors.Post && (<span className="text-danger"><small>{errors.Post.message}</small></span>)}
                  </span>
                </div>
                <div className="col-sm-6 dw-mt">
                  <span>
                    <select className="nice-select" type="text" id=""
                      {...register("PrefferedMode", { required: "Please select preffered mode." })}>
                      <option value="">Preffered Mode</option>
                      <option value="From Home">From Home</option>
                      <option value="From Office">From Office</option>
                    </select>
                    <label className="input_label"><span className="input__label-content"></span></label>
                    {errors.PrefferedMode && (<span className="text-danger"><small>{errors.PrefferedMode.message}</small></span>)}
                  </span>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-sm-6">
                  <span className="form-group">
                    <label className="text-dark">Experiance *</label>
                    <input className="form-control" type="text"
                      {...register("Experiance", { required: "Please enter experiance." })} />
                    {errors.Experiance && (<span className="text-danger"><small>{errors.Experiance.message}</small></span>)}
                  </span>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="text-dark">Current CTC *</label>
                    <input className="form-control" type="text" {...register("CurrentCTC", { required: "Please enter current CTC." })} />
                    {errors.CurrentCTC && (<span className="text-danger"><small>{errors.CurrentCTC.message}</small></span>)}
                  </div>
                </div>
              </div>
            </div>
          </li>
          <hr />
          <li>
            <div className="content">
              <div className="career-number"><strong>2</strong></div>
              <h3 className="pt-2 text-dark">Personal Details</h3>
            </div>

            <div className="contact-form">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-dark">Candidate Name *</label>
                    <input type="text" name="name" className="form-control"
                      {...register("Name", {
                        required: "Please enter name.",
                        pattern: { value: /^[a-z A-Z]+$/, message: "Please enter valid name." }
                      })} />
                    {errors.Name && (<span className="text-danger"><small>{errors.Name.message}</small></span>)}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-dark">Address *</label>
                    <input type="text" name="address" className="form-control"
                      {...register("Address", { required: "Please enter address." })} />
                    {errors.Address && (<span className="text-danger"><small>{errors.Address.message}</small></span>)}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-dark">Contact Number *</label>
                    <input type="number" name="phone_number" className="form-control" maxLength={10}
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      {...register("Contact", {
                        required: "Please enter contact number.",
                        pattern: { value: /^[0][1-9]\d{9}$|^[1-9]\d{9}$/, message: "Please enter valid contact number." }
                      })} />
                    {errors.Contact && (<span className="text-danger"><small>{errors.Contact.message}</small></span>)}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-dark">Email ID *</label>
                    <input type="text" name="msg_subject" className="form-control"
                      {...register("Email", {
                        required: "Please enter email ID.",
                        pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Please enter valid email ID." }
                      })} />
                    {errors.Email && (<span className="text-danger"><small>{errors.Email.message}</small></span>)}
                  </div>
                </div>
              </div>
            </div>
          </li>
          <hr />
          <li>
            <div className="content">
              <div className="career-number"><strong>3</strong></div>
              <h3 className="pt-2 text-dark">Upload Resume</h3>
            </div>
            <div className="contact-form">
              <div className="row">
                <div className="form-group">
                  <label className="text-dark">Resume *</label>
                  <input type="file" accept=".pdf, .doc, .docx" name="name" className="form-control"
                    {...register("Resume", { required: "Please upload resume.", })} />
                  {errors.Resume && (<span className="text-danger"><small>{errors.Resume.message}</small></span>)}
                  {resumeValidation && (<div className={alertClass} role="alert">{msg}</div>)}
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="col-lg-12 col-md-12 text-center">
              {loading ? <div className="loader"></div> :
                <button type="submit" className="default-btn btn-bg-one border-radius-50">
                  Submit<i className="bx bx-chevron-right"></i>
                </button>}
            </div>
          </li>
        </ul>
        {isShow && (<div className={alertClass} role="alert">{msg}</div>)}
      </form>
    </>
  );
}
