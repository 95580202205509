import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import HeaderBanner from "../common/HeaderBanner";
import Service from "../Services/Service";

export default function Contact() {
  const [loading, setLoading] = useState(false);

  //showing alert-
  const [isShow, setIsShow] = useState(false);
  const [alertClass, setAlertClass] = useState();
  const [msg, setMsg] = useState();

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const saveData = (data) => {
    setLoading(true);
    Service.saveEnquiry(data).then((res) => {
      //alert
      setIsShow(true);
      setAlertClass("alert alert-success");
      // setMsg(res.data.Success);
      setMsg("Success. Thank you for message. We'll get back to you soon.");
      //loader
      setLoading(false);
      reset();
      setTimeout(() => { setIsShow(false) }, 5000);
    });
  };

  return (
    <>
      <HeaderBanner pageName='Contact Us' />

      <div className="contact-form-area pt-100 pb-70">
        <div className="container">
          <div className="section-title text-center"><h2>Let's Send Us a Message Below</h2></div>
          <div className="row pt-45">
            <div className="col-lg-4">
              <div className="contact-info mr-20">
                <span className="primary">Contact Info</span>
                <h2>Let's Connect With Us</h2>
                <p>We’ve worked with clients of all sizes, from startups to enterprise brands. Let’s talk about your project and how we can help provide value.{" "}</p>
                <ul>
                  <li>
                    <div className="content">
                      <i className="bx bx-phone-call"></i>
                      <h3>Phone Number</h3>
                      <a href="tel:+919422333387">+91 942 233 3387</a>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <i className="bx bxs-map"></i>
                      <h3>Address</h3>
                      <span>Arnav Technosys, Building No. 06, Mundhe Apt., Mathura Shinde Nagar, Paregaon Road, Yeola, Nashik-423401.</span>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <i className="bx bx-message"></i>
                      <h3>Contact Info</h3>
                      <a href="mailto:info@arnavtechnosys.com"><span className="__cf_email__">info@arnavtechnosys.com</span></a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              {isShow && (<div className={alertClass} role="alert">{msg}</div>)}
              <div className="contact-form">
                <form onSubmit={handleSubmit(saveData)}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Your Name <span>*</span></label>
                        <input type="text" name="name" className="form-control"
                          {...register("Name", { required: "Please enter your name." })} />
                        {errors.Name && (<span className="text-danger"><small>{errors.Name.message}</small></span>)}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Your Email <span>*</span></label>
                        <input type="email" name="email" className="form-control"
                          {...register("Email", { required: "Please enter your email." })} />
                        {errors.Email && (<span className="text-danger"><small>{errors.Email.message}</small></span>)}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Phone Number <span>*</span></label>
                        <input type="number" name="phone_number" className="form-control" maxLength={10}
                          onInput={(e) => {
                            if (e.target.value.length > e.target.maxLength)
                              e.target.value = e.target.value.slice(0, e.target.maxLength);
                          }}
                          {...register("Contact", {
                            required: "Please enter contact number.",
                            pattern: { value: /^[0][1-9]\d{9}$|^[1-9]\d{9}$/, message: "Please enter valid contact number." }
                          })} />
                        {errors.Contact && (<span className="text-danger"><small>{errors.Contact.message}</small></span>)}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Your Subject <span>*</span></label>
                        <input type="text" name="msg_subject" className="form-control"
                          {...register("Subject", { required: "Please enter subject." })}
                        />
                        {errors.Subject && (<span className="text-danger"><small>{errors.Subject.message}</small></span>)}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Your Message <span>*</span></label>
                        <textarea name="message" className="form-control" cols="30" rows="8"
                          {...register("Message", { required: "Please enter message." })}></textarea>
                        {errors.Message && (<span className="text-danger"><small>{errors.Message.message}</small></span>)}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="agree-label">
                        <input type="checkbox" id="chb1" name="chb1"
                          {...register("Accept", { required: "Please accept the terms and conditions." })} />
                        <label htmlFor="chb1">Accept{" "}
                          <Link to="/terms-condition">Terms & Conditions</Link>{" "} And <Link to="/privacy-policy">Privacy Policy.</Link>
                        </label>
                      </div>
                      {errors.Accept && (<span className="text-danger"><small>{errors.Accept.message}</small></span>)}
                    </div>
                    <div className="col-lg-12 col-md-12 text-center">
                      {loading ? <div className="loader"></div> :
                        <button type="submit" className="default-btn btn-bg-two border-radius-50">Send Message <i className="bx bx-chevron-right"></i></button>}
                      {/* <div id="msgSubmit" className="h3 text-center hidden"></div><div className="clearfix"></div> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="map-area">
        <div className="container-fluid m-0 p-0">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50194.82376159623!2d-79.09792989247224!3d38.159337740034566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b4a08eb8621697%3A0xe5d6e4710a09b66e!2sStaunton%2C%20VA%2024401%2C%20USA!5e0!3m2!1sen!2sbd!4v1607173226867!5m2!1sen!2sbd"></iframe>
        </div>
      </div> */}
      <div className="container">
        <div className="section-title text-center">
          <h2 className="fw-bolder clr-secondary">Our Existence</h2>
          <h4>We are available in below places. we providing services at remote places as well.</h4>
          <center><p>Headquartered in the Yeola and operating internationally, our team
            drives digital transformation for businesses globally. We ensure
            on-site presence to deliver services to our major target markets in:</p></center>
        </div>
        <div className="row pt-5 mt-30 justify-content-center">
          <div className="col-lg-4 col-sm-6 pb-5">
            <div className="card mt-3 h-100">
              <div className="shadow bg-white rounded-circle mx-auto text-center service-icon-div pt-4">
                {/* <p className="mt-2 fw-bolder1">Yeola</p> */}
                <i className="fa fa-building fa-2x head-icon service-icon" aria-hidden="true"></i>
              </div>
              <div className="card-body text-center">
                <h3 className="pt-1">Registered Office: Yeola</h3>
                <p>Arnav Technosys, Building No. 06, Mundhe Apt., Mathura Shinde Nagar, Paregaon Road, Yeola, <br /> Nashik-423401.</p>
                <div className="align-text-bottom">
                  <span className="clr-primary fw-bolder new-line primary"><small>Contact Number:</small></span>
                  <div className="row">
                    <div className="col-6">
                      <span className="text-uppercase clr-primary fw-bolder new-line primary">
                        <small><a className="secondary" href="tel:02559266784">(02559) 266784</a></small>
                      </span>
                    </div>
                    <div className="col-6">
                      <span className="text-uppercase clr-primary fw-bolder new-line primary">
                        <small><a className="secondary" href="tel:+919623167904">+91 942 233 3387</a></small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 pb-5">
            <div className="card mt-3 h-100">
              <div className="shadow bg-white rounded-circle mx-auto text-center service-icon-div pt-4">
                <i className="fa fa-location-dot fa-2x head-icon service-icon" aria-hidden="true"></i>
              </div>
              <div className="card-body text-center">
                <iframe
                  title="Yeola"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3748.2630904168304!2d74.47779141428114!3d20.039420026190367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdc38bf13ee90a3%3A0xb17dc0cc90a75e35!2sArnav%20Technosys!5e0!3m2!1sen!2sin!4v1649653638511!5m2!1sen!2sin"
                  style={{ border: "0" }}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}