import axios from "axios";

// const Base_Url = "http://localhost:5000/atds-api/";
const Base_Url = "https://atjoin.in/atds-api/";
class Service {
  //Arnav services
  getAllVacancy() { return axios.get(Base_Url + "vacancy/getAll-vacancy"); }

  getVacancy(data) { return axios.post(Base_Url + "vacancy/get-vacancy", data); }

  getAllCategory() { return axios.get(Base_Url + "category/get-category"); }

  getAllJobtype() { return axios.get(Base_Url + "jobtype/get-jobtype"); }

  getAllLocation() { return axios.get(Base_Url + "location/get-location"); }

  applyJob(data) { return axios.post(Base_Url + "apply/applyjob", data); }

  //Debug Services-

  careerFreshers(data) { return axios.post(Base_Url + "careerFreshers/career-fresher", data); }

  careerExperiance(data) { return axios.post(Base_Url + "careerExperiance/careerExperiance", data); }

  saveEnquiry(data) { return axios.post(Base_Url + "careerFreshers/save-enquiry", data); }

  // Display Careers

  getAllFreshers() { return axios.get(Base_Url + "careerFreshers/get-career-freshers"); }

  getAllExperienced() { return axios.get(Base_Url + "careerExperiance/get-career-experienced"); }
}
export default new Service();
