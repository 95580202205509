import React from 'react'
import { Link } from 'react-router-dom'
import HeaderBanner from '../common/HeaderBanner'

export default function Services() {
  return (
    <>
      <HeaderBanner pageName='Services' />

      <section className="services-widget-area pt-100 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <span className="primary">Our Services</span>
            <h2>We Provide a Wide Variety of It Services</h2>
          </div>
          <div className="row pt-45">
            <div className="col-lg-4 col-md-6">
              <div className="services-item">
                <Link to="/it-cosulting"><img src="assets/images/services/services-img1.jpg" alt="Images" /></Link>
                <div className="content">
                  <i className="flaticon-consultant"></i>
                  <span><Link to="/it-cosulting">IT Solution</Link></span>
                  <h3><Link to="/it-cosulting">IT Consulting</Link></h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="services-item">
                <Link to="/app-dev"><img src="assets/images/services/services-img2.jpg" alt="Images" /></Link>
                <div className="content">
                  <i className="flaticon-consulting"></i>
                  <span><Link to="/app-dev">App Development</Link></span>
                  <h3><Link to="/app-dev">App Development</Link></h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="services-item">
                <Link to="/web-dev"><img src="assets/images/services/services-img3.jpg" alt="Images" /></Link>
                <div className="content">
                  <i className="flaticon-web-development"></i>
                  <span><Link to="/web-dev">Web Services</Link></span>
                  <h3><Link to="/web-dev">Web Development</Link></h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="services-item">
                <Link to="/business"><img src="assets/images/services/services-img4.jpg" alt="Images" /></Link>
                <div className="content">
                  <i className="flaticon-stats"></i>
                  <span><Link to="/business">Business</Link></span>
                  <h3><Link to="/business">Business Reform</Link></h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="services-item">
                <Link to="/infrastructure"><img src="assets/images/services/services-img5.jpg" alt="Images" /></Link>
                <div className="content">
                  <i className="flaticon-structure"></i>
                  <span><Link to="/infrastructure">Planing</Link></span>
                  <h3><Link to="/infrastructure">Infrastructure</Link></h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="services-item">
                <Link to="/data-analysis"><img src="assets/images/services/services-img6.jpg" alt="Images" /></Link>
                <div className="content">
                  <i className="flaticon-data-analytics"></i>
                  <span><Link to="/data-analysis">Analysis</Link></span>
                  <h3><Link to="/data-analysis">Data Analysis</Link></h3>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-12 col-md-12 text-center">
              <div className="pagination-area">
                <Link to="services-1.html" className="prev page-numbers">
                  <i className='bx bx-left-arrow-alt'></i>
                </Link>
                <span className="page-numbers current" aria-current="page">1</span>
                <Link to="services-1.html" className="page-numbers">2</Link>
                <Link to="services-1.html" className="page-numbers">3</Link>
                <Link to="services-1.html" className="next page-numbers">
                  <i className='bx bx-right-arrow-alt'></i>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="services-widget-area pt-50 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <span className="primary">Service Partners</span>
            <h2>Partnership and Collaboration</h2>
            <h4 className='primary mt-3'>Partnerships & Alliances</h4>
          </div>
          <div className="row pt-45">
            <div className="col-lg-4 col-md-6">
              <div className="services-item">
                <a href='https://atjoin.in/' target='_blank' rel="noreferrer">
                  <img src="assets/images/services/partners/ATJ.png" alt="Images" />
                </a>
                <div className="content">
                  <i className="flaticon-consultant"></i>
                  <span><a href='https://atjoin.in/' target='_blank' rel="noreferrer">ATJOIN PVT. LTD.</a></span>
                  <h3><a href='https://atjoin.in/' target='_blank' rel="noreferrer">IT Services</a></h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="services-item">
                <a href='https://debugsolutions.co.in/' target='_blank' rel="noreferrer">
                  <img src="assets/images/services/partners/DS.png" alt="Images" />
                </a>
                <div className="content">
                  <i className="flaticon-consulting"></i>
                  <span><a href='https://debugsolutions.co.in/' target='_blank' rel="noreferrer">Debug Solutions</a></span>
                  <h3><a href='https://debugsolutions.co.in/' target='_blank' rel="noreferrer">Training Partner</a></h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="services-item">
                <a href='https://assimilatetechnologies.com/' target='_blank' rel="noreferrer">
                  <img src="assets/images/services/partners/AST.png" alt="Images" />
                </a>
                <div className="content">
                  <i className="flaticon-web-development"></i>
                  <span><a href='https://assimilatetechnologies.com/' target='_blank' rel="noreferrer">Assimilate Technologies</a></span>
                  <h3><a href='https://assimilatetechnologies.com/' target='_blank' rel="noreferrer">Joint Venture</a></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <section className="clients-area pt-100 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <span className="sp-color2 primary">Our Clients</span>
            <h2>We are Trusted by 45+ Countries Worldwide</h2>
          </div>
          <div className="clients-slider owl-carousel owl-theme pt-45">
            <div className="clients-content">
              <div className="content">
                <img src="assets/images/clients-img/clients-img1.jpg" alt="Images" />
                <i className='bx bxs-quote-alt-left'></i>
                <h3>Jonthon Martin</h3>
                <span>Manager of Company</span>
              </div>
              <p>
                “Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor,
                nisi elit consequat ipsum, nec sagittis.
                sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi loren
                accumsan ipsum velit.”
              </p>
            </div>
            <div className="clients-content">
              <div className="content">
                <img src="assets/images/clients-img/clients-img2.jpg" alt="Images" />
                <i className='bx bxs-quote-alt-left'></i>
                <h3>Alin Decros</h3>
                <span>CEO of HTM Company</span>
              </div>
              <p>
                “Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor,
                nisi elit consequat ipsum, nec sagittis.
                sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi loren
                accumsan ipsum velit.”
              </p>
            </div>
            <div className="clients-content">
              <div className="content">
                <img src="assets/images/clients-img/clients-img3.jpg" alt="Images" />
                <i className='bx bxs-quote-alt-left'></i>
                <h3>Elen Musk</h3>
                <span>CEO, MT Corporation</span>
              </div>
              <p>
                “Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor,
                nisi elit consequat ipsum, nec sagittis.
                sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi loren
                accumsan ipsum velit.”
              </p>
            </div>
          </div>
        </div>
        <div className="client-circle">
          <div className="client-circle-1">
            <div className="circle"></div>
          </div>
          <div className="client-circle-2">
            <div className="circle"></div>
          </div>
          <div className="client-circle-3">
            <div className="circle"></div>
          </div>
          <div className="client-circle-4">
            <div className="circle"></div>
          </div>
          <div className="client-circle-5">
            <div className="circle"></div>
          </div>
          <div className="client-circle-6">
            <div className="circle"></div>
          </div>
          <div className="client-circle-7">
            <div className="circle"></div>
          </div>
        </div>
      </section> */}


      {/* <div className="brand-area ptb-100">
        <div className="container">
          <div className="brand-slider owl-carousel owl-theme">
            <div className="brand-item">
              <img src="assets/images/brand-logo/brand-logo1.png" className="brand-logo-one" alt="Images" />
              <img src="assets/images/brand-logo/brand-style1.png" className="brand-logo-two" alt="Images" />
            </div>
            <div className="brand-item">
              <img src="assets/images/brand-logo/brand-logo2.png" className="brand-logo-one" alt="Images" />
              <img src="assets/images/brand-logo/brand-style2.png" className="brand-logo-two" alt="Images" />
            </div>
            <div className="brand-item">
              <img src="assets/images/brand-logo/brand-logo3.png" className="brand-logo-one" alt="Images" />
              <img src="assets/images/brand-logo/brand-style3.png" className="brand-logo-two" alt="Images" />
            </div>
            <div className="brand-item">
              <img src="assets/images/brand-logo/brand-logo4.png" className="brand-logo-one" alt="Images" />
              <img src="assets/images/brand-logo/brand-style4.png" className="brand-logo-two" alt="Images" />
            </div>
            <div className="brand-item">
              <img src="assets/images/brand-logo/brand-logo5.png" className="brand-logo-one" alt="Images" />
              <img src="assets/images/brand-logo/brand-style5.png" className="brand-logo-two" alt="Images" />
            </div>
            <div className="brand-item">
              <img src="assets/images/brand-logo/brand-logo3.png" className="brand-logo-one" alt="Images" />
              <img src="assets/images/brand-logo/brand-style3.png" className="brand-logo-two" alt="Images" />
            </div>
          </div>
        </div>
      </div> */}

    </>
  )
}
