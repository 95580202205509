import React from 'react';
import { Link } from "react-router-dom";

function HeaderBanner(props) {
  return (
    <div className="inner-banner pt-66">
      <div className="container">
        <div className="inner-title">
          <h4 className='text-light text-uppercase'>{props.pageName}</h4>
          <ul>
            <li><Link to='/'>Home</Link></li>
            <li><i className="bx bx-chevrons-right"></i></li>
            <li>{props.pageName}</li>
          </ul>
        </div>
      </div>
      <div className="inner-shape"><img src="assets/images/shape/inner-shape.png" alt="Images" /></div>
    </div>
  )
}

export default HeaderBanner