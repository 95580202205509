import React from 'react'
import HomeCounter from '../common/HomeCounter'
import { Link } from 'react-router-dom'
import HeaderBanner from '../common/HeaderBanner'
import useScript from "../script/ScriptLoader";

export default function About() {
  useScript("./assets/js/custom.js");

  const securityComputing = [
    {
      icon: 'flaticon-cyber-security',
      title: 'Business Security',
      desc: 'Business security can refer to many different aspects of company protection. It may mean the physical security of the premises, especially against loss prevention, or it could refer to safeguarding computer systems against potential hackers who may try to steal company information.'
    },
    {
      icon: 'flaticon-computer',
      title: 'Manage IT Service',
      desc: 'IT services refers to the application of business and technical expertise to enable organizations in the creation, management and optimization of or access to information and business processes. The IT services market can be segmented by the type of skills that are employed to deliver the service (design, build, run).'
    },
    {
      icon: 'flaticon-effective',
      title: 'Product Analysis',
      desc: 'Product analysis involves examining product features, costs, availability, quality, appearance and other aspects. Product analysis is conducted by potential buyers, by product managers attempting to understand competitors and by third party reviewers.'
    },
    {
      icon: 'flaticon-implement',
      title: 'Analytic Solution',
      desc: 'An analytical solution involves framing the problem in a well-understood form and calculating the exact solution. A numerical solution means making guesses at the solution and testing whether the problem is solved well enough to stop.'
    },
    {
      icon: 'flaticon-consulting',
      title: 'Finest Quality',
      desc: 'Quality management software (QMS) can help manufacturers measure (and therefore improve) the quality of their products and processes. This software category can refer to a broad range of applications that help manufacturers ensure quality across all supply chain activities—from design to production to distribution and eventually, service.'
    },
    {
      icon: 'flaticon-consultant',
      title: 'Risk Management',
      desc: 'A software project can be concerned with a large variety of risks. In order to be adept to systematically identify the significant risks which might affect a software project, it is essential to classify risks into different classes. The project manager can then check which risks from each class are relevant to the project.'
    },
  ]

  const ourClients = [
    { title: 'Jagdamba Cement Products', logo: 'jagdamba-logo.png' },
    { title: 'Vijayshil', logo: 'vijayshil-logo.png' },
    { title: 'Pohegaon Nagari Sahakari Patsanstha', logo: 'pohegaon-logo.png' },
    { title: 'Shri Shivaji Shikshan Prasarak Mandal', logo: 'shivaji-logo.png' },
    { title: 'Ved Paithani', logo: 'ved-logo.png' },
    { title: 'Sai Siddhi Multispeciality Hospital', logo: 'sai-logo.png' },
    { title: 'Smile Care Clinic', logo: 'smile-logo.png' },
    { title: 'Sagar Trading', logo: 'sagar-logo.png' },
    { title: 'MKVL Vivah', logo: 'mkvl-logo.png' },
    { title: 'Bhagvati Enterprises', logo: 'bhagvati-logo.png' },
    { title: 'Dr. Bhajiwala', logo: 'bhajiwala-logo.png' },
    { title: 'Shubhangi Textiles NX', logo: 'shubhangi-logo.png' }
  ]

  return (
    <>
      <HeaderBanner pageName='About Us' />

      <div className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-play">
                <img src="assets/images/about/about-img1.jpg" alt="About Images" />
                <div className="about-play-content">
                  <span>View Our Intro</span>
                  <h2>Perfect Solution for IT Services!</h2>
                  {/* <div className="play-on-area">
                    <Link to="https://www.youtube.com/watch?v=tUP5S4YdEJo" className="play-on popup-btn"><i
                      className='bx bx-play'></i></Link>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content ml-25">
                <div className="section-title">
                  <span className="primary">6+ Years of Experience</span>
                  <h2>Right Partner for Software Innovation</h2>
                  <p>What we do, Whatever we do, we do with your end user in mind...😊</p>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <ul className="about-list text-start">
                      <li><i className='bx bxs-check-circle'></i>Software Development</li>
                      <li><i className='bx bxs-check-circle'></i>UI/UX Designing</li>
                      <li><i className='bx bxs-check-circle'></i>Application Services</li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <ul className="about-list about-list-2 text-start">
                      <li><i className='bx bxs-check-circle'></i>IT Consulting</li>
                      <li><i className='bx bxs-check-circle'></i>Testing & QA</li>
                      <li><i className='bx bxs-check-circle'></i>Help Desk Service</li>
                    </ul>
                  </div>
                </div>
                <p className="about-content-text justify-both">
                  <strong>Arnav Technosys</strong>, we closely follow new trends in all aspects of Web Designing, Software & Web Development and provide a solution that effectively implements fresh ideas and innovative approach. Our team of experts treats every client independently; we place great focus on your demands and are honestly fascinated in the success of your business. We have the abilities to turn your whole online project into a huge success.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="choose-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="choose-content mr-20">
                <div className="section-title">
                  <span className="primary">Why Choose Us</span>
                  <h2>We Provide Truly Prominent IT Solutions.</h2>
                  <p className='justify-both'>We help our partners accelerate disruption both within their organizations and industries. They strategize new ideas and obtain real business value by getting the most out of our production-ready custom software development services. Talk to our experts today!</p>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-6">
                    <div className="choose-content-card">
                      <div className="content"><i className="flaticon-practice primary"></i><h3>Experience</h3></div>
                      <p className='justify-both'>Founded in 2016, Arnav Technosys is a provider of IT consulting and website, software & mobile applications development & services company.We closely follow new trends in all aspects of Web & Mobile Application Designing, Software & Web Development.</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-6">
                    <div className="choose-content-card">
                      <div className="content"><i className="flaticon-help primary"></i><h3>Quick Support</h3></div>
                      <p className='justify-both'>Having started as a small Web & Mobile Apps product company, we switched to large services in 2021 and ever since we have helped non-IT organizations and software product companies improve business performance and quickly win new customers.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6"><div className="choose-img"><img src="assets/images/choose-img.jpg" alt="Images" /></div></div>
          </div>
        </div>
      </div>
      <div className="security-area pt-100 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <span className="primary">IT Security & Computing</span>
            <h2>Searching for a Solution! We Provide Truly Prominent IT Solutions</h2>
          </div>
          <div className="row pt-45">
            {securityComputing && securityComputing.map((item, i) => (
              <div className="col-lg-4 col-sm-6 mt-2" key={i}>
                <div className="security-card h-100">
                  <i className={item.icon}></i>
                  <h3><Link to="productDetails">{item.title}</Link></h3>
                  <p className='justify-both'>{item.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="brand-area-two ptb-100">
        <div className="container">
          <div className="section-title text-center">
            {/* <span className="sp-color2 primary">Clients</span> */}
            <h2 className='text-light'>Our Clients</h2>
          </div>
          <div className="brand-slider owl-carousel owl-theme mt-3">
            {ourClients && ourClients.map((item, i) => (
              <div className="brand-item text-center" key={i}><h6 style={{ color: '#ffc221' }}>{item.title}</h6></div>
            ))}
          </div>
        </div>
      </div>
      <HomeCounter />
    </>
  )
}
