import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom'

function DisplayAllFreshers() {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [loading, setLoading] = useState(false);
  //showing alert-
  const [isShow, setIsShow] = useState(false);
  const [alertClass, setAlertClass] = useState();
  const [msg, setMsg] = useState();

  let navigate = useNavigate();

  const loadLoginData = (data) => {
    setLoading(true);
    if (data.Username === "ATJAdmin" && data.Password === "ATJAdmin@4566$") {
      localStorage.setItem("isLoggedIn", true);
      navigate('/view-all-freshers');
    } else {
      setLoading(false);
      setIsShow(true);
      setAlertClass("alert alert-danger");
      setMsg("Login Failed. Invalid Username or Password.");
      setTimeout(() => { setIsShow(false); }, 5000);
      localStorage.setItem("isLoggedIn", false);
    }
    reset();
  }

  return (
    <div className="contact-form-area pt-25 mt-30 pb-70 career-btn-mt">
      <div className="container">
        {/* <div className="section-title text-center"><h2>Let's Send Us a Message Below</h2></div> */}
        <div className="contact-info mr-20 card" style={{ backgroundColor: '#fff' }}>
          <div className="contact-form mt-3">
            <form onSubmit={handleSubmit(loadLoginData)}>
              <h1 className='text-center'>Login</h1>
              <ul>
                <div className="row mt-3">
                  <li>
                    <div className="col-sm-12">
                      <span className="form-group">
                        <label className="text-dark">Username *</label>
                        <input className="form-control" type="text"
                          {...register("Username", { required: "Please enter username." })} />
                        {errors.Username && (<span className="text-danger"><small>{errors.Username.message}</small></span>)}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="text-dark">Password *</label>
                        <input className="form-control" type="password" {...register("Password", { required: "Please enter Password." })} />
                        {errors.Password && (<span className="text-danger"><small>{errors.Password.message}</small></span>)}
                      </div>
                    </div>
                  </li>
                </div>
                <li>
                  <div className="col-lg-12 col-md-12 text-center">
                    {loading ? <div className="loader"></div> :
                      <button type="submit" className="default-btn btn-bg-one border-radius-50">
                        Submit<i className="bx bx-chevron-right"></i>
                      </button>}
                  </div>
                </li>
              </ul>
              {isShow && (<div className={alertClass} role="alert">{msg}</div>)}
            </form>
          </div>
        </div>
      </div>
    </div >
  )
}

export default DisplayAllFreshers