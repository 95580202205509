import React from 'react'
import { Navigate, BrowserRouter, Route, Routes } from 'react-router-dom'
import Site from '../components/site/Site'
import Home from '../components/site/Home'
import About from '../components/site/About'
import Contact from '../components/site/Contact'
import Products from '../components/site/Products'
import Services from '../components/site/Services'
import Careers from '../components/site/Careers'
import CareersFreshers from '../components/site/CareersFreshers'
import CareersExperienced from '../components/site/CareersExperienced'
import AppDevelopment from '../components/ServicesComponents/AppDevelopment';
import WebDevelopment from '../components/ServicesComponents/WebDevelopment';
import BusinessReform from '../components/ServicesComponents/BusinessReform';
import DataAnalyst from '../components/ServicesComponents/DataAnalysis';
import ITConsulting from '../components/ServicesComponents/ITConsulting';
import Infrastructure from '../components/ServicesComponents/Infrastructure';
import EduMaster from '../components/ProductsComponents/EduMaster'
import Atbeloved from '../components/ProductsComponents/Atbeloved'
import AtOpd from '../components/ProductsComponents/AtOpd'
import InspireBusiness from '../components/ProductsComponents/InspireBusiness'
import Matrimony from '../components/ProductsComponents/Matrimony'
import ApplyNow from '../components/site/ApplyNow'
import PrivacyPolicy from '../components/site/PrivacyPolicy'
import TnC from '../components/site/TnC'
import ViewAllFreshers from '../components/careers/ViewAllFreshers'
import ViewAllExperienced from '../components/careers/ViewAllExperienced'
import Login from '../components/careers/Login'

const useAuth = () => {
  const userLogin = JSON.parse(localStorage.getItem('isLoggedIn'));
  if (userLogin) {
    return true
  } else {
    return false
  }
}

export default function Router() {
  const auth = useAuth();
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Site />}>
          <Route path='' element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='contact' element={<Contact />} />
          <Route path='products' element={<Products />} />
          <Route path='services' element={<Services />} />
          {/* <Route path='careers' element={<Careers />} /> */}
          <Route path='careers-fresher' element={<CareersFreshers />} />
          <Route path='careers-experienced' element={<CareersExperienced />} />
          <Route path='job' element={<ApplyNow />} />
          {/* Service Components */}
          <Route path='app-dev' element={<AppDevelopment />} />
          <Route path='web-dev' element={<WebDevelopment />} />
          <Route path='business' element={<BusinessReform />} />
          <Route path='data-analysis' element={<DataAnalyst />} />
          <Route path='it-cosulting' element={<ITConsulting />} />
          <Route path='infrastructure' element={<Infrastructure />} />
          {/* Products Components */}
          <Route path='edumaster' element={<EduMaster />} />
          <Route path='atbeloved' element={<Atbeloved />} />
          <Route path='atopd' element={<AtOpd />} />
          <Route path='inspire' element={<InspireBusiness />} />
          <Route path='matrimony' element={<Matrimony />} />

          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='terms-condition' element={<TnC />} />
          {/* Careers */}
          <Route path='view-all-freshers' element={auth ? <ViewAllFreshers /> : <Navigate to='/login' />} />
          <Route path='view-all-experienced' element={auth ? <ViewAllExperienced /> : <Navigate to='/login' />} />
          <Route path='login' element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
