import React from 'react'
import HeaderBanner from '../common/HeaderBanner'

function PrivacyPolicy() {
  return (
    <>
      <HeaderBanner pageName='Privacy Policy' />
      <div class="container">
        <div class="my-5">
          <p>Working together on various projects, Arnav Technosys  collects and processes a lot of data. This Privacy Policy will help you understand how this data is collected, used and how your personal information is stored. While you use Arnav Technosys &#8217; services, you agree to the terms of this Privacy Policy. This policy is subject to changes from time to time as per the operational, legal and regulatory requirements. If we make changes to the Privacy Policy, we will post it on the site. By continuing use of our site and services, after these changes are posted, you agree to the revised policy.</p>
          <p>NOTE: We gather only that information which is relevant to the project and helps us serve you better.</p>
          <p> 1. We understand that the information you provide to us while working on projects is confidential and hence we maintain it in private files on our secure web servers. Only authorized personnel have access to this data. We may use this information to ensure compliance with legal requirements. Arnav Technosys  will always ask for your consent before sharing your information with third parties. The data you provide is used to improve and provide you services such as fulfilling orders, processing payments, invoicing, emails etc.</p>
          <p>2. The type of information may include, but is not limited to Name, Address, Email, Phone Number, Address and such other relevant information. We may also collect information about you visiting our website and how you use it. This may include your IP address, geographical location, device information, browser information, length of visit number of page views and such similar information. This is collected with the help of cookies. We may also use cookies and pixels in our emails to track delivery and open rates.</p>
          <p>3. You agree that when we receive any email from you, we may retain the content of the email and that we shall use the email ID and mobile number information for sending promotional or informational message about the business.</p>
          <p>4. The information collected is used strictly for business purposes. The contact information is used to keep you updated about our products and services. It may also be used to analyses the traffic on our site and to improve our services if need be. The information is also used to ensure that we comply with legal requirements.</p>
          <p>5. For compliance purposes, we may share your information with Government authorities or use it as internal references which could be mutually beneficial for both businesses.</p>
          <p>6. We have security measures in place to protect the loss, misuse and alteration of the information under our control. The data resides behind a firewall, with access restricted to authorized personnel.</p>
          <p>7. Cookies are used to make sure that you get the most of all the features on our site. Cookies are small text files that are placed on the device you use to access our site. It allows us to monitor and analyses our site activity to offer better services to our visitors. If you do not wish for cookies to be installed on your device, you can modify your browser settings to reject cookies.</p>
          <p>8. Some of our pages may contain content and links to other websites or apps that are owned by third parties. This privacy policy does not apply to them. Please refer to their terms and conditions and privacy policy separately before use. <br /><br />
            If you have any questions about any of the terms in this privacy policy, please contact <a class="cpolicy-link" href="mailto:info@arnavtechnosys.com">info@arnavtechnosys.com</a>
          </p>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy