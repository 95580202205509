import React from 'react'

export default function HomeCounter() {
  return (
    <>
      <div className="counter-area pt-100 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <span className="sp-color2 primary">Numbers Are Talking</span>
            <h2>Let’s Check Our Business Growth and Success Story</h2>
            <p>
              Founded in 2016, Arnav Technosys is a provider of IT consulting and
              website, software & mobile application development & services
              company. Having started as a small Web & Mobile Apps product
              company.
            </p>
          </div>
          <div className="row pt-45">
            <div className="col-lg-3 col-6 col-md-3">
              <div className="counter-another-content"><i className="flaticon-web-development"></i><h3>250+</h3><span>Delivered Goods</span></div>
            </div>
            <div className="col-lg-3 col-6 col-md-3">
              <div className="counter-another-content"><i className="flaticon-consulting-1"></i><h3>100+</h3><span>IT Consulting</span></div>
            </div>
            <div className="col-lg-3 col-6 col-md-3">
              <div className="counter-another-content"><i className="flaticon-startup"></i><h3>250+</h3><span>Fully Launched</span></div>
            </div>
            <div className="col-lg-3 col-6 col-md-3">
              <div className="counter-another-content"><i className="flaticon-tick"></i><h3>250+</h3><span>Project Completed</span></div>
            </div>
          </div>
        </div>
        <div className="counter-shape">
          <div className="shape1"><img src="assets/images/shape/shape1.png" alt="Images" /></div>
          <div className="shape2"><img src="assets/images/shape/shape2.png" alt="Images" /></div>
        </div>
      </div>
    </>
  )
}
