import React from 'react'

export default function Header() {
  return (
    <>
      <header className="top-header top-header-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6">
              <div className="top-head-left w-100">
                <div className="top-contact w-100">
                  <div className='row'>
                    <h3 className='col-lg-4'>Support:{" "}<a href="tel:+919422333387">+91 942 233 3387</a></h3>
                    <h3 className='col-lg-6'>Email Us:{" "}<a href="mailto:info@arnavtechnosys.com">info@arnavtechnosys.com</a></h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6">
              <div className="top-header-right">
                <div className="top-header-social">
                  <ul>
                    <li><a href="https://www.facebook.com/ArnavTechnosys/" target="_blank" rel="noreferrer"><i className="bx bxl-facebook"></i></a></li>
                    <li><a href="https://twitter.com/arnavtechnosys" target="_blank" rel="noreferrer"><i className="bx bxl-twitter"></i></a></li>
                    <li>
                      <a href="https://in.linkedin.com/company/arnavtechnosys" target="_blank" rel="noreferrer">
                        <i className="bx bxl-linkedin-square"></i>
                      </a>
                    </li>
                    <li><a href="https://www.instagram.com/arnavtechnosys/" target="_blank" rel="noreferrer"><i className="bx bxl-instagram"></i></a></li>
                    <li><a href="https://wa.me/919422333387" target="_blank" rel="noreferrer"><i className="bx bxl-whatsapp"></i></a></li>
                  </ul>
                </div>
                {/* <!-- <div className="language-list">
              <select className="language-list-item">
                <option>English</option>
                <option>العربيّة</option>
                <option>Deutsch</option>
                <option>Português</option>
                <option>简体中文</option>
              </select>
            </div> --> */}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
