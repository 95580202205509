import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const goToTop = () => window.scrollTo(0, 0);
  return (
    <>
      <footer className="footer-area" style={{ backgroundColor: 'rgb(34,163,185)' }}>
        <div className="container">
          <div className="footer-top pt-50 pb-30">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="footer-widget">
                  {/* <div className="footer-logo"> */}
                  <Link to="/">
                    {/* <img src="assets/images/logos/footer-logo.png" alt="Images" /> */}
                    <h3 className="service-h3">Arnav Technosys</h3>
                  </Link>
                  {/* <h3 className="company-name">Arnav Technosys</h3> */}
                  {/* </div> */}
                  <p className='justify-both'>Committed to quality, Arnav Technosys provides a broad set of services that include web solutions and mobile apps that are modern, persuasive and intuitive to help develop a transformative user experience.</p>
                  <p className='justify-both'>We closely follow new trends in all aspects of Web & Mobile Application Designing, Software & Web Development and provide a solution that effectively implements fresh ideas and innovative approach.</p>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div className="footer-widget pl-2">
                  <h3>SERVICES:</h3>
                  <ul className="footer-list">
                    <li><Link to="/it-cosulting" onClick={goToTop} rel="noreferrer"><i className="bx bx-chevron-right"></i>IT Consultancy</Link></li>
                    <li><Link to="/business" onClick={goToTop} rel="noreferrer"><i className="bx bx-chevron-right"></i>Business Solution</Link></li>
                    <li><Link to="/it-cosulting" onClick={goToTop} rel="noreferrer"><i className="bx bx-chevron-right"></i>Digital Services</Link></li>
                    <li><Link to="/business" onClick={goToTop} rel="noreferrer"><i className="bx bx-chevron-right"></i>Business Reform</Link></li>
                    <li><Link to="/web-dev" onClick={goToTop} rel="noreferrer"><i className="bx bx-chevron-right"></i>Web Development</Link></li>
                    {/* <li><Link to="/" onClick={goToTop} rel="noreferrer"><i className="bx bx-chevron-right"></i>Cloud Computing</Link></li> */}
                    <li><Link to="/data-analysis" onClick={goToTop} rel="noreferrer"><i className="bx bx-chevron-right"></i>Data Analysis</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div className="footer-widget pl-2">
                  <h3>PRODUCTS:</h3>
                  <ul className="footer-list">
                    <li><Link to="/edumaster" onClick={goToTop} rel="noreferrer"><i className="bx bx-chevron-right"></i>EduMaster</Link></li>
                    <li><Link to="/inspire" onClick={goToTop} rel="noreferrer"><i className="bx bx-chevron-right"></i>Inspire Business</Link></li>
                    <li><Link to="/atopd" onClick={goToTop} rel="noreferrer"><i className="bx bx-chevron-right"></i>ATOPD</Link></li>
                    <li><Link to="/atbeloved" onClick={goToTop} rel="noreferrer"><i className="bx bx-chevron-right"></i>ATBELOVED</Link></li>
                    <li><Link to="/matrimony" onClick={goToTop} rel="noreferrer"><i className="bx bx-chevron-right"></i>Matrimony App</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="footer-widget">
                  <h3>ADDRESS:</h3>
                  <p className='justify-both'>Arnav Technosys, Building No. 06, Mundhe Apt., Mathura Shinde Nagar, Paregaon Road, Yeola, Nashik-423401.</p>
                  <div className="footer-call-content mt-2">
                    <h3>Talk to Our Experts</h3>
                    <span><a href="tel:+919422333387">+91 942 233 3387</a></span>
                    <i className="bx bx-headphone"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copy-right-area">
            <div className="copy-right-text">
              <p>Copyright ©{currentYear}. All Rights Reserved by
                <a href="https://arnavtechnosys.com/" onClick={goToTop} rel="noreferrer"><strong className="secondary">&nbsp;Arnav Technosys</strong>🧡</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
