import React from 'react'
import { Link } from 'react-router-dom'
import HomeCounter from '../common/HomeCounter'
import useScript from "../script/ScriptLoader";

export default function Home() {
  useScript("./assets/js/custom.js");

  const workingProcess = [
    {
      no: '01',
      title: 'Discovery',
      desc: 'The discovery phase, also known as the scoping phase, is a process in which information is collected and analyzed about the project, such as information, the intended audience and market. This helps clarify the goals, limitations, and the overall scope of the project.'
    },
    {
      no: '02',
      title: 'Planning',
      desc: `Project planning is a discipline addressing how to complete a project in a certain timestamp, usually with defined stages and designated resources.One view of project planning divides the activity into these steps:
             1. Setting measurable objectives
             2. Identifying deliverables
             3. Scheduling.`
    },
    {
      no: '03',
      title: 'Execute',
      desc: 'Project Execution is the phase in the project life cycle when the work is performed, and everything in the project plan is put into action. Project Execution is the phase in the project life cycle when the work is performed.'
    },
    {
      no: '04',
      title: 'Deliver',
      desc: 'As changes or conflicts arise, project managers must manage the risk associated with such noise. That is, if a project is going over budget or past its deadline, it is the project manager’s responsibility to track such risks throughout the delivery phase to ensure there will be no surprises down the line.'
    }
  ]

  const ourServices = [
    {
      style: 'flaticon-consultant',
      title: 'IT Consulting',
      desc: 'Our experts can help to develop and implement an effective IT strategy, assist in smooth digital transformation and system integration as well as advise on improvements to your digital customer experience.'
    },
    {
      style: 'flaticon-consulting',
      title: 'Career Guidance',
      desc: 'Career counseling is a type of advice-giving and support provided by career counselors to their clients, to help the clients manage their journey through life, learning and work changes.'
    },
    {
      style: 'flaticon-web-development',
      title: 'Web Development',
      desc: 'The development of reliable and scalable software solutions for any OS, browser and device. We bring together deep industry expertise and the latest IT advancements to deliver custom solutions and products that perfectly fit the needs and behavior of their users.'
    },
    {
      style: 'flaticon-stats',
      title: 'Business Reform',
      desc: 'Business environment reform(BER) aims to reduce the costs and risks of business activity by improving poor government policies, laws and regulations, and by stimulating competition through new market entrants.'
    },
  ]

  const caseStudy = [
    { title: 'EduMaster', desc1: 'Education', desc2: 'Exam', image: 'assets/images/case-study/edumaster.png', path: '/edumaster' },
    { title: 'Inspire Business', desc1: 'Business', desc2: 'Marketing', image: 'assets/images/case-study/ib.png', path: '/inspire' },
    { title: 'ATBELOVED', desc1: 'Family', desc2: 'Connective', image: 'assets/images/case-study/atbeloved.png', path: '/atbeloved' },
    { title: 'ATOPD', desc1: 'Health', desc2: 'Medical', image: 'assets/images/case-study/atopd.png', path: '/atopd' },
    { title: 'Matrimony', desc1: 'Social Work', desc2: 'Help', image: 'assets/images/case-study/mkvlvivah.png', path: '/matrimony' }
  ]

  const clientReview = [
    { review: 'I got good website in reasonable prices thanks AT team.', name: 'Mr. Sagar Patel', business: 'Patel Opticals' },
    { review: 'Arnav technology Institute is the best.', name: 'Sakshi Deshmukh', business: 'Business Man' },
    { review: 'Very good service provider & affordable cost.', name: 'Vicky Takare', business: 'Business Man' }
  ]

  return (
    <>
      {/* <Navbar /> */}
      <div className="banner-five-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="banner-five-content">
                <span className="primary">TECHNOLOGY & IT SOLUTIONS</span>
                <h1>IT Solutions, <b>Digital</b> Technology & Services All Around the World</h1>
                <p>WEBSITE DESIGN & DEVELOPMENT, MOBILE APPLICATION CONSULTING <br />& DEVELOPMENT FOR YOUR DIGITAL SUCCESS</p>
                <Link to="/contact" className="default-btn btn-bg-two border-radius-50">Get A Quote</Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-five-img"><img src="assets/images/home-img.png" alt="Images" /></div>
            </div>
          </div>
        </div>
      </div>
      <section className="work-process-area pt-100 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <span className="primary">Our Working Process</span>
            <h2>How Our Services Will Help You to Grow Your Business</h2>
          </div>
          <div className="row pt-45">
            {workingProcess && workingProcess.map((item, i) => (
              <div className="col-lg-3 col-sm-6 wp-mt" key={i}>
                <div className="work-process-card-three h-100">
                  <div className="number-title">{item.no}</div>
                  <h3>{item.title}</h3>
                  <p style={{ whiteSpace: 'pre-line' }}>{item.desc}</p>
                  <i className="flaticon-project"></i>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="about-area about-bg2 pt-100 pb-70">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img-4"><img src="assets/images/about/about-img.png" alt="About Images" /></div>
            </div>
            <div className="col-lg-6">
              <div className="about-content-3 ml-20">
                <div className="section-title">
                  <span className="primary">About Us</span>
                  <h2>Right Partner for Software Innovation</h2>
                  <p className='justify-both'>
                    <span className="text-primary">Arnav Technosys,</span>We closely follow new trends in all aspects of Web Designing, Software &
                    Web Development and provide a solution that effectively implements fresh ideas and innovative approach
                  </p>
                </div>
                <h3>We Have 6+ Years Of Experience. We Offer IT Solutions, Digital Technology Services</h3>
                <div className="all-skill-bar">
                  <div className="skill-bar" data-percentage="90%">
                    <h4 className="progress-title-holder clearfix">
                      <span className="progress-title">Analytics</span>
                      <span className="progress-number-wrapper">
                        <span className="progress-number-mark" style={{ left: "90%" }}><span className="percent" >90%</span></span>
                      </span>
                    </h4>
                    <div className="progress-content-outter"><div className="progress-content" style={{ width: "90%" }}></div></div>
                  </div>
                  <div className="skill-bar mb-0" data-percentage="80%">
                    <h4 className="progress-title-holder clearfix">
                      <span className="progress-title">Solutions</span>
                      <span className="progress-number-wrapper">
                        <span className="progress-number-mark" style={{ left: "80%" }}><span className="percent">80%</span></span>
                      </span>
                    </h4>
                    <div className="progress-content-outter"><div className="progress-content" style={{ width: "80%" }}></div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="services-area-four pt-100 pb-70">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="services-left">
                <div className="section-title">
                  <span className="sp-color2 primary">Our Services</span>
                  <h2>Searching for a Solution! We Provide Truly IT Solutions</h2>
                  <p className="justify-both">We help our partners accelerate disruption both within their organizations and industries. They strategize new ideas and
                    obtain real business value by getting the most out of our production-ready custom software development services. Talk to our experts today!</p>
                </div>
                <Link to="/services" className="default-btn btn-bg-two border-radius-50 text-center">View All Services</Link>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                {ourServices && ourServices.map((item, i) => (
                  <div className="col-lg-6 col-sm-6 mt-2" key={i}>
                    <div className="services-card services-card-color-bg h-100">
                      <i className={item.style}></i>
                      <h3><Link to="/services">{item.title}</Link></h3>
                      <p className='justify-both'>{item.desc}</p>
                      <Link to="/services" className="learn-btn">Learn More <i className="bx bx-chevron-right"></i></Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="clients-area-three pt-100">
        <div className="container">
          <div className="section-title text-center">
            <span className="sp-color2 primary">Our Clients</span>
            <h2>Our Clients Feedback</h2>
          </div>
          <div className="clients-slider-two owl-carousel owl-theme">
            {clientReview && clientReview.map((review, index) => (
              <div className="clients-slider-item" key={index}>
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    {/* <div className="clients-slider-img"> */}
                    <img src="assets/images/clients-img/feedback.png" alt="Images" />
                    <div className="clients-slider-circle"></div>
                    {/* </div> */}
                  </div>
                  <div className="col-lg-6">
                    <div className="clients-slider-content">
                      <i className="bx bxs-quote-alt-left"></i>
                      <p className="justify-both">“{review.review}”</p>
                      <h3>{review.name}</h3>
                      <span>{review.business}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <HomeCounter />
      <div className="case-study-area-two pt-50 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <span className="sp-color2 primary">Case Study</span>
            <h2>Introduce Our Projects and Check Recent Work</h2>
          </div>
          <div className="row justify-content-center pt-45">
            {caseStudy && caseStudy.map((item, i) => (
              <div className="col-lg-4 col-sm-6" key={i}>
                <div className="case-study-item">
                  <Link to={item.path}><img src={item.image} alt="Images" /></Link>
                  <div className="content">
                    <h3><Link to={item.path}>{item.title}</Link></h3>
                    <ul>
                      <li><Link to={item.path}>{item.desc1}</Link></li>
                      <li><Link to={item.path}>{item.desc2}</Link></li>
                    </ul>
                    <Link to={item.path} className="more-btn"><i className="bx bx-right-arrow-alt"></i></Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <section className="technology-area-four pt-100 pb-70">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="technology-right">
                <div className="section-title">
                  <span className="sp-color2 primary">Technology Index</span>
                  <h2>We Deliver Our Best Solution With The Goal of Trusting</h2>
                  <p className='justify-both'>We help our partners accelerate disruption both within their organizations and industries. They strategize new ideas and
                    obtain real business value by getting the most out of our production-ready custom software development services. Talk to our experts today!</p>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-6 col-sm-3">
                    <div className="technology-item"><i className="flaticon-android"></i><h3>Android</h3></div>
                  </div>
                  <div className="col-lg-3 col-6 col-sm-3">
                    <div className="technology-item"><i className="flaticon-website"></i><h3>Web</h3></div>
                  </div>
                  <div className="col-lg-3 col-6 col-sm-3">
                    <div className="technology-item"><i className="flaticon-apple"></i><h3>ios</h3></div>
                  </div>
                  <div className="col-lg-3 col-6 col-sm-3">
                    <div className="technology-item"><i className="flaticon-computer"></i><h3>Desktop</h3></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="technology-play-area">
                {/* <Link to="https://www.youtube.com/watch?v=tUP5S4YdEJo" className="play-btn popup-btn">
                  <i className="bx bx-play"></i>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
